import React from "react";
import Footer from "components/Footer";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import constants from "site-constants";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

const siteUrl = constants.siteUrl;

export default function Terms() {
  const { t } = useTranslation("common", "terms");

  return (
    <>
      <Helmet>
        <title>{t`pageTitle`}</title>
        <meta name="title" content={t`pageTitle`} />
        <meta name="description" content={t`pageDescription`} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:title" content={t`pageTitle`} />
        <meta property="og:description" content={t`pageDescription`} />
        <meta property="og:image" content="/images/share.png" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={siteUrl} />
        <meta property="twitter:title" content={t`pageTitle`} />
        <meta property="twitter:description" content={t`pageDescription`} />
        <meta property="twitter:image" content="/images/share.png" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-1Y8LCEBD3R"
        ></script>
        <script>{`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-1Y8LCEBD3R');
`}</script>
      </Helmet>

      <div className="px-4 py-16 mx-auto text-gray-300 max-w-7xl sm:px-6 lg:py-20 lg:px-8">
        <div className="max-w-[200px] mb-6">
          <Link to="/" lang="sr-Cyrl">
            <img src="/images/symbol.svg" alt="" width="563px" height="241px" />
          </Link>
        </div>
        <div className="support-page">
          <h1>{t`terms:title`}</h1>
          <p>{t`terms:p1`}</p>
          <p>{t`terms:p2`}</p>
          <p>
            <Trans key="terms:p3">
              Ауторска права над овим сајтом и у вези садржаја овог сајта су
              заштићена под условима лиценце{" "}
              <a
                href="https://creativecommons.org/licenses/by/3.0/deed.sr"
                target="_blank"
                rel="noopener noreferrer"
              >
                Creative Commons Ауторство 3.0 Србија
              </a>
              .
            </Trans>
          </p>
          <p>{t`terms:p4`}</p>
          <p>{t`terms:p5`}</p>

          <ul className="list-disc">
            <li>{t`terms:li1`}</li>
            <li>{t`terms:li2`}</li>
            <li>{t`terms:li3`}</li>
          </ul>

          <p>{t`terms:p6`}</p>
        </div>

        <div className="mt-12">
          <Footer />
        </div>
      </div>
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "terms"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
